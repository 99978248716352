.Toastify {
  .Toastify__toast:not(.snack) {
    padding: 15px 10px;
    border-radius: 3px;
    color: #7b8794;
    .btn {
      padding: 0.4rem 1rem;
      min-width: 100px;
    }
  }
  .Toastify__toast--success:not(.snack) {
    background: white;
    border-left: 3px solid #7bb026;
    .icon {
      color: #7bb026;
    }
  }
  .Toastify__toast--info:not(.snack) {
    background: white;
    border-left: 3px solid #2186eb;
    .icon {
      color: #2186eb;
    }
  }
  .Toastify__toast--error:not(.snack) {
    background: white;
    border-left: 3px solid #ef4e4e;
    .icon {
      color: #ef4e4e;
    }
  }

  .Toastify__toast--warn {
    background: white;
    border-left: 3px solid #f7c948;
    .icon {
      color: #f7c948;
    }
    .btn-warning {
      color: white;
    }
  }

  .Toastify__progress-bar {
    height: 3px;
  }

  .Toastify__close-button {
    color: #7b8794;
    font-weight: 400;
  }
  .Toastify__toast.snack {
    background-color: white;
    min-height: auto;
    padding: 10px 15px;
    border-radius: 3px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  .Toastify__toast-container--top-center {
    width: auto;
  }
}
