body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

b, strong {
  font-weight: 600;
}

.tox.tox-tinymce {
  border-radius: 6px;
  border-width: 1px;

  .tox-editor-header {
    z-index: 1;
  }
}

#notif-popover {
  max-width: 100%;
  font-size: inherit;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.3);
  border: none;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.d-none {
  display: none;
}

.slide-in-top {
  animation: slide-in-top 0.3s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-out-top {
  animation: slide-out-top 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-1000px);
    opacity: 0;
  }
}

.variable {
  cursor: default;
  background-color: #894FE8;
  color: #FFF;
  padding: 0 10px;
  border-radius: 14px;
  font-style: normal;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.mce-mention {
  color: #5ac8fa;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  td,
  th {
    text-align: left;
    border-bottom: 1px solid #eee;
    padding: 16px;
  }

  th {
    font-weight: 600;
    text-transform: uppercase;
    opacity: .5;
  }
}